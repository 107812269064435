import React, { useEffect, useRef } from 'react';
import styles from "../../styles/animations_styles/first_visit.module.css";
import { useNavigate } from "react-router-dom";
const FirstVisitDemoAnimationV1 = ({ hideLogoAnimation }) => {
    let navigate = useNavigate();
    const swipeUpActive = useRef(false);
    const swipeLeftActive = useRef(false);
    const mainBox = useRef(null);
    const centerSeciton = useRef(null);
    const rightHiddenBox = useRef(null);
    const rightHiddenBoxText = useRef(null);
    const welcomDing = useRef(null);
    const arrowWrapper = useRef(null);
    const arrowAnim = useRef(null);
    const goldCoin = useRef(null);
    useEffect(() => {
        setTimeout(
            () => showWelcomeDialogue(),
            100
        );
        setTimeout(
            () => moveUp(),
            5000
        );
        setTimeout(
            () => moveDown(),
            8000
        );
        setTimeout(
            () => moveLeft(),
            8300
        );
        setTimeout(
            () => moveRight(),
            11000
        );
        setTimeout(
            () => arrowDirectionTopRight(),
            11500
        );
        setTimeout(
            () => navigateToRedeem(),
            16500
        );
        function moveRight() {
            centerSeciton.current.classList.remove(`${styles.banner_section_leftMove}`);
            rightHiddenBox.current.classList.remove(`${styles.swipeLeft}`);
            rightHiddenBoxText.current.classList.remove(`${styles.rightBoxShowH2}`);
            setTimeout(() => {
                localStorage.setItem("dv", 1);
                // hideLogoAnimation(0);
            }, 1500);
        }
        function moveLeft() {
            centerSeciton.current.classList.add(`${styles.banner_section_leftMove}`);
            rightHiddenBox.current.classList.add(`${styles.swipeLeft}`);
            rightHiddenBoxText.current.classList.add(`${styles.rightBoxShowH2}`);
            arrowWrapper.current.classList.add(`${styles.fadeOut}`);
        }
        function moveDown() {
            mainBox.current.classList.remove(`${styles.swipeUp}`);
        }
        function moveUp() {
            welcomDing.current.classList.add(`${styles.hide}`);
            arrowWrapper.current.classList.remove(`${styles.hide}`);
            mainBox.current.classList.add(`${styles.swipeUp}`);
        }
        function arrowDirectionTopRight() {
            arrowAnim.current.classList.remove(`${styles.arrowAnim90}`);
            arrowAnim.current.classList.add(`${styles.arrowAnim134}`);
            arrowWrapper.current.classList.remove(`${styles.fadeOut}`);
            goldCoin.current.classList.remove(`${styles.fadeOut}`);
        }
        function navigateToRedeem() {
            navigate('/redeem');
        }
        function showWelcomeDialogue() {
            welcomDing.current.classList.remove(`${styles.fadeOut}`);
        }
        return;
    }, []);
    return (
        <>
            <div className={styles.mainDemo} ref={mainBox} onContextMenu={(e) => e.preventDefault()}>
                <div ref={welcomDing} className={`${styles.welcomeDingWrapper} ${styles.fadeOut} text-white`}>
                    <h2 style={{ fontSize: "20px", textAlign: "center" }}>
                        Welcome to Ding <br />
                        Your one stop for all your <br />
                        <span>entertainment, infotainment and edutainment</span>
                    </h2>
                </div>
                <div ref={arrowWrapper} className={`${styles.hide}`}>
                    <div className={styles.arrowAnim90} ref={arrowAnim}>
                        <div className={styles.arrowSliding}>
                            <div className={styles.arrow}></div>
                        </div>
                        <div className={`${styles.arrowSliding} ${styles.delay1}`}>
                            <div className={styles.arrow}></div>
                        </div>
                        <div className={`${styles.arrowSliding} ${styles.delay2}`}>
                            <div className={styles.arrow}></div>
                        </div>
                        <div className={`${styles.arrowSliding} ${styles.delay3}`}>
                            <div className={styles.arrow}></div>
                        </div>
                    </div>
                </div>
                <div ref={goldCoin} className={`${styles.welcomeDingWrapper} text-white ${styles.fadeOut}`}>
                    <h2 style={{ fontSize: "20px", textAlign: "center" }}>
                        Every second <br />
                        you spend <br />
                        to watch video, <br />
                        you earn one GOLD COIN
                    </h2>
                </div>
                <div className={styles.centerSectionHandler}>

                    <div className={styles.banner_section} ref={centerSeciton}>
                        <figure className={styles.banner}></figure>
                        <h1 className={styles.title}>ding</h1>
                    </div>

                    <div className={styles.rightBox} ref={rightHiddenBox}>
                        <h2 className={styles.rightSectionH2} ref={rightHiddenBoxText}>
                            swipe left <br />
                            to enter <br />
                            <span className={styles.fs2rem}>trivia</span>
                        </h2>
                    </div>

                </div>

                <div className={styles.bottomSection}>
                    <h2>
                        swipe up <br />
                        to browse <br />
                        <span className={styles.fs2rem}>videos</span>
                    </h2>
                </div>
            </div>
        </>
    )
}


export default React.memo(FirstVisitDemoAnimationV1);
