import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import styles from '../../styles/profile/editProfile.module.css';
import ding_token from '../../images/v1/home/ding_token.png';
import { editUser, saveUser } from "../../ApiCalls/Profile/api_v1_profile";
import { useNavigate } from "react-router-dom";
// import SfxMainAudio from "../AutoPlayAudio/SfxMainAudio";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

function EditProfile() {
    let navigate = useNavigate();
    let tel = useRef();
    let coins = useRef();
    const isLoading = useRef(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    // State to manage the selected gender
    const [gender, setGender] = useState("") // Initialize with an empty string

    // Function to handle changes in the gender radio buttons
    const handleGenderChange = (event) => {
        setGender(event.target.value)
    };
    const items = [
        <div className={styles.imgWrap} style={{ width: "120px", margin: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={ding_token} alt="" />
        </div>
    ]
    const [carouselItems, setCarouselItems] = useState(items); // Initialize with hardcoded items
    const activeSlide = useRef(0); // State to keep track of the active slide index
    const handleDragStart = (e) => e.preventDefault();
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#3d3c3a";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#3d3c3a");
        document.title = "Edit Profile | Ding";
    }, []);
    useEffect(() => {
        editUser().then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }

            // const valuesArray = JSON.parse(response.data);
            // let result = text.slice(0, 5);
            let data = response.data.data;
            let avatars = data.avatars;
            // Map over the avatars array and create carousel items
            const avatarItems = avatars.map((avatar, index) => (
                <div key={"carousel-" + index} className={styles.imgWrap} data-uuid={avatar.uuid}>
                    <img src={avatar.avatar_url} alt="loading..." />
                </div>
            ));
            setCarouselItems(avatarItems);
        })
            .catch(error => {
                // console.log(error);
                // console.log(error.response.status,error.response.data.message);
            })
    }, [])

    function handleClick() {
        navigate('/home');
    }
    function editProfile() {
        navigate('/edit-profile');
    }

    const responsiveItems = {
        0: {
            items: 1,
        }
    }
    const handleInitialized = () => {
        const nextButton = document.querySelector('.alice-carousel__next-btn-item span[data-area]');
        const prevButton = document.querySelector('.alice-carousel__prev-btn-item span[data-area]');
        if (prevButton) {
            prevButton.removeAttribute('data-area');
        }
        if (nextButton) {
            nextButton.removeAttribute('data-area');
        }
    };
    const handleSlideChanged = (e) => {
        const activeSlideIndex = e.slide;

        // Get the active slide element using the class name of alice-carousel__stage-item
        const activeSlideElement = document.querySelector('.alice-carousel__stage-item.__active .editProfile_imgWrap__D2HQc');

        // Check if the activeSlideElement is available before accessing its data-uuid attribute
        // if (activeSlideElement) {
        // Get the UUID from the data attribute of the active slide element
        const activeSlideUUID = activeSlideElement.getAttribute('data-uuid');
        activeSlide.current = activeSlideUUID;
        // }
    };

    function handleSubmit(event) {
        event.preventDefault(); // Prevent the default form submission behavior
        // Get the form data
        setIsDisabled(true);
        const button = event.target;
        // Save the original styles
        const originalBorderStyle = button.style.border;
        const originalColorStyle = button.style.color;
        // Modify the styles
        button.style.border = '2px solid gray';
        button.style.color = 'gray';
        button.innerHTML = "Please Wait"
        const formData = {
            "name": nameRef.current.value,
            "email": emailRef.current.value,
            "gender": gender,
            "dpuuid": activeSlide.current
        };


        saveUser(formData).then(response => {
            setIsDisabled(false);
            button.style.border = originalBorderStyle;
            button.style.color = originalColorStyle;
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }

            if (response.status === 200) {
                button.innerHTML = "Profile Updated";
                setTimeout(() => {
                    navigate("/profile")
                }, 1000);

            }

        })
            .catch(error => {
                // console.log(error);
            })
    }
    return (
        <>
            {/* <SfxMainAudio /> */}
            <main className={styles.main}>
                <section className={styles.profileSection}>
                    <h1 className='letter-spacing-12'>Edit Profile</h1>
                    <div id={styles.editProfileAliceCarouselWrapper} className="editProfileAliceCarouselWrapper">
                        <AliceCarousel
                            touchTracking={false}
                            mouseTracking={false}
                            responsive={responsiveItems}
                            disableDotsControls={true}
                            infinite={true}
                            items={carouselItems} // Use the dynamic carouselItems here
                            onInitialized={handleInitialized}
                            onSlideChanged={handleSlideChanged} // Assign the function to onSlideChanged prop
                        >
                        </AliceCarousel>
                    </div>
                </section>
                <section className='formSection'>
                    <form className={styles.editForm}>
                        <input className="formInput" type="text" placeholder='Name' name='name' required="true" ref={nameRef} />
                        <input className="formInput" type="text" placeholder='Email' name='email' required="true" ref={emailRef} />
                        <div className='d-flex' style={{ justifyContent: "center" }}>
                            <div id={styles.genderWraper}>
                                <label>
                                    <input
                                        type="radio"
                                        value="male"
                                        checked={gender === 'male'}
                                        onChange={handleGenderChange}
                                    />
                                    Male
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="female"
                                        checked={gender === 'female'}
                                        onChange={handleGenderChange}
                                    />
                                    Female
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="other"
                                        checked={gender === 'other'}
                                        onChange={handleGenderChange}
                                    />
                                    Other
                                </label>
                            </div>
                        </div>
                        <button type='submit' className={`${styles.backToVideos} mt-3`} onClick={handleSubmit} disabled={isDisabled}>Save Profile</button>
                    </form>
                </section>
                <section className={styles.buttonSection}>
                    <button type='button' className={styles.backToVideos} onClick={handleClick} disabled={isDisabled}>back to videos</button>
                </section>
            </main>
        </>
    )
}

export default React.memo(EditProfile);