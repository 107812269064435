import React, { useLayoutEffect, useEffect, useState, useMemo, useRef } from 'react';
import { questionRetrive, questionSubmit } from "../../ApiCalls/Home/api_v1_home";
import { useNavigate, useLocation } from "react-router-dom";
import styles from '../../styles/question_time/QuestionTime.module.css';
import triviaTrack from '../../audio/trivia.aac';
import correctTrack from '../../audio/correct.wav';
import incorrectTrack from '../../audio/incorrect.wav';
// import ding_token from '../../images/v1/home/ding_token.png';
import timerSmoke from "../../animations/triv.webm";
import star_token from '../../images/v1/home/token.png';

function QuestionTime() {
    let videoId = (new URLSearchParams(window.location.search)).get("id")
    const location = useLocation();
    // const [questionData, setQuestionData] = useState([]);
    const [questionUUID, setQuestionUUID] = useState();
    const questionTitle = useRef(null);
    const questionChoice1 = useRef(null);
    const questionChoice2 = useRef(null);
    const questionChoice3 = useRef(null);
    const questionChoice4 = useRef(null);
    const correctPromt = useRef(null);
    const [choices, setChoices] = useState(4);
    const [attempts, setAttempts] = useState(1);
    let navigate = useNavigate();
    const [seconds, setSeconds] = useState(10);
    const interval = useRef(null)
    const triviaNumber = useRef(2);
    const triviaTrackPlaying = useRef(false);
    var track = useRef(new Audio(triviaTrack));
    const correct = useMemo(() => new Audio(correctTrack), []);
    // useRef(new Audio(correctTrack));
    const incorrect = useMemo(() => new Audio(incorrectTrack), []);
    // useRef(new Audio(incorrectTrack));
    const [showLinear, setShowLinear] = useState(0);
    const showInterval = useRef(null);
    const intervalCount = useRef(0);
    const intervalState = useRef(false);
    const totalQuestions = useRef(0);
    const [isDisabled, setIsDisabled] = useState(false);
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#f06e00";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f06e00");
        document.title = "Trivia | Ding";
    }, []);

    useEffect(() => {

        if (seconds < 0) {
            let smoke = document.getElementById("playSmoke");
            smoke.currentTime = 0;
            stopCounter();
            pauseTrack();
            smoke.pause();
            setTimeout(() => {
                setShowLinear(0);
                intervalCount.current = 0;
                showInterval.current = setInterval(showOneBYoNE, 400);
                playTrack();
            }, 1800);
            smoke.play();


            // track.current.pause(); 
            // setSeconds(0)
        }
        // if (seconds == 10) {
        //      startCounter() 
        //     }
    }, [seconds]);

    useEffect(() => { if (attempts && attempts > 1) { stopCounter(); pauseTrack(); clearInterval(showInterval.current); intervalState.current = false; } }, [attempts])

    const startCounter = () => interval.current = setInterval(() => {
        setSeconds(prevState => prevState - 1)
    }, 1000)

    const stopCounter = () => clearInterval(interval.current);

    useEffect(() => {

        touchstart();

        return () => {
            // second
            pauseTrack();
            // track.current.pause();
            // console.log("cleaned up");
        }
    }, [])

    const touchstart = () => {

        if (triviaTrackPlaying.current == false) {
            triviaTrackPlaying.current = true;
            // track.loop=true;
            playTrack();
            // track.current.play();

        }
    };
    const playTrack = () => {
        track.current.pause();
        track.current.currentTime = 0;
        track.current.volume = 0.07;
        track.current.play();
    }
    const pauseTrack = () => {
        triviaTrackPlaying.current = false;
        track.current.pause();
    }
    const playCorrect = () => {
        correct.volume = 0.03;
        correct.play();
    }
    const playIncorrect = () => {
        incorrect.volume = 0.05;
        incorrect.play();
    }
    useEffect(() => {
        totalQuestions.current++;
        let attemptNumber;
        if (location.state != null) {
            attemptNumber = location.state.attemptNumber;
            totalQuestions.current = 0;
            stopCounter();
            setAttempts(attemptNumber);
            location.state = null;
        } else {
            attemptNumber = attempts;
        }
        // console.log(attemptNumber, totalQuestions.current);
        let dataQuestion = {
            "videoId": videoId,
            "questionId": attemptNumber,
        }
        questionRetrive(dataQuestion).then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            if (response.data.message === "Ko" && response.data.data.trivia === "no quiz available") {
                //    return navigate('/home',{state:{id:videoId}})   
                return navigate('/rewards', { state: { id: videoId, questionUUID: questionUUID } })
            }
            else {
                touchstart();
                intervalCount.current = 0;
                setShowLinear(0);
                let data = response.data.data;
                // console.log(data.title);
                // let title = data.title;
                triviaNumber.current = data.trivia;
                let customArray = [data.quiz.c1, data.quiz.c2, data.quiz.c3, data.quiz.c4];
                let totalChoices = 0;
                customArray.forEach(element => {
                    if (element) {
                        totalChoices++;
                    }
                });
                if (totalChoices == 4) {
                    setChoices(4)
                }
                if (totalChoices == 3) {
                    setChoices(3)
                }
                if (totalChoices == 2) {
                    setChoices(2)
                }
                if (totalChoices == 2) {
                    let newCustomArray = [];
                    customArray.forEach(element => {
                        if (element) {
                            newCustomArray.push(element);
                        }
                    });


                    let shuffeledArray = newCustomArray.sort((a, b) => 0.3 - Math.random());
                    questionTitle.current.innerHTML = data.quiz.title

                    questionChoice1.current.value = shuffeledArray[0];
                    questionChoice1.current.innerHTML = shuffeledArray[0];

                    questionChoice2.current.value = shuffeledArray[1];
                    questionChoice2.current.innerHTML = shuffeledArray[1];
                    setQuestionUUID(data.questionUUID);


                }
                if (totalChoices == 3) {
                    let newCustomArray = [];
                    customArray.forEach(element => {
                        if (element) {
                            newCustomArray.push(element);
                        }
                    });
                    let shuffeledArray = newCustomArray.sort((a, b) => 0.3 - Math.random());
                    questionTitle.current.innerHTML = data.quiz.title

                    questionChoice1.current.value = shuffeledArray[0];
                    questionChoice1.current.innerHTML = shuffeledArray[0];

                    questionChoice2.current.value = shuffeledArray[1];
                    questionChoice2.current.innerHTML = shuffeledArray[1];

                    questionChoice3.current.value = shuffeledArray[2];
                    questionChoice3.current.innerHTML = shuffeledArray[2];
                    setQuestionUUID(data.questionUUID);

                }
                if (totalChoices == 4) {
                    let shuffeledArray = customArray.sort((a, b) => 0.3 - Math.random());

                    let newCustomArray2 = []; //this array will hold all of above and none of above.
                    let newCustomArray3 = []; //this array will not hold all of above and none of above.
                    let finalArray = []; //final array will hold elements by concatenation of newcustomarray2 nd 3;
                    setQuestionUUID(data.questionUUID);
                    questionTitle.current.innerHTML = data.quiz.title;
                    shuffeledArray.forEach(element => {
                        if ((element !== "All of the above") && (element !== "All of above") && (element !== "None of the above")) {
                            newCustomArray2.push(element);
                            // console.log("true",element);
                        }
                        if (element && (element === "All of the above") || (element === "All of above") || (element === "None of the above")) {
                            newCustomArray3.push(element);
                            // console.log('false',element);
                        }
                    });
                    newCustomArray2.sort((a, b) => 0.3 - Math.random());
                    finalArray = newCustomArray2.concat(newCustomArray3);
                    questionChoice1.current.value = finalArray[0];
                    questionChoice1.current.innerHTML = finalArray[0];

                    questionChoice2.current.value = finalArray[1];
                    questionChoice2.current.innerHTML = finalArray[1];

                    questionChoice3.current.value = finalArray[2];
                    questionChoice3.current.innerHTML = finalArray[2];

                    questionChoice4.current.value = finalArray[3];
                    questionChoice4.current.innerHTML = finalArray[3];
                }

                if (intervalState.current == false) {
                    intervalState.current = true;
                    showInterval.current = setInterval(showOneBYoNE, 350);
                }

                // questionUUID.current.value = data.questionUUID;

                // console.log(questionChoice1,questionChoice2,questionChoice3,questionChoice4)


            }
        })
            .catch(error => {
                // console.log(error);
                // console.log(error.response.status,error.response.data.message);
            })
    }, [attempts]);

    function showOneBYoNE() {
        intervalCount.current++;
        // console.log(intervalCount.current);
        if (intervalCount.current === 5) {
            setSeconds(10);
            startCounter();
        }
        if (intervalCount.current == 15) {
            setShowLinear(prevState => prevState - 1);
            clearInterval(showInterval.current);
            intervalState.current = false;
        }
        if (intervalCount.current <= 7) {
            setShowLinear(prevState => ++prevState);
        }

    }


    const handleSubmit = (e) => {
        clearInterval(interval.current);
        setIsDisabled(true);
        e.preventDefault();
        const button = e.target;
        // Save the original styles
        const originalBorderStyle = button.style.border;
        const originalColorStyle = button.style.color;
        // Modify the styles
        button.style.border = '2px solid gray';
        button.style.color = 'gray';

        let redClr = "#bd0d0f";
        let greenClr = "#d5f04b";
        let origionalClrBtn = "#3d3c3a";
        let origionalClrBg = "#f06e00";
        let color = 0;
        let colorInterval;
        let submitedAnswer = e.target.value;
        // let prompt = document.getElementById('corectPrompt');
        let answerData = { "videoId": videoId, "questionUUID": questionUUID, "questionNumber": attempts, "answer": submitedAnswer };
        questionSubmit(answerData).then(response => {
            button.style.border = originalBorderStyle;
            button.style.color = originalColorStyle;
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            if (response.data.message === "Ko") {
                return navigate('/home', { state: { id: videoId } })
            }
            if (response.data.data.status == "false") {
                playIncorrect();
                colorInterval = setInterval(myTimer, 100);
                function myTimer() {
                    e.target.style.backgroundColor = redClr; //changed color
                    correctPromt.current.innerHTML = response.data.data.hint;
                    correctBtnHighlight(response.data.data.hint, 1);
                    color++;
                    // console.log(color);
                    if (color & 1) {
                        // console.log("odd");
                        document.body.style.backgroundColor = redClr;
                    }
                    else {
                        // console.log("even");
                        document.body.style.backgroundColor = origionalClrBg;
                    }

                    // }
                    //restore origional color
                    if (color > 5) {
                        clearInterval(colorInterval);
                        document.body.style.backgroundColor = origionalClrBg;
                        // correctPromt.current.innerHTML = "";
                        setTimeout(
                            () => removePromptText(e, false),
                            3000
                        );
                        setTimeout(
                            () => correctBtnHighlight(response.data.data.hint, 0),
                            3000
                        );
                    }
                }
            }
            else if (response.data.data.status == "true") {
                playCorrect();
                colorInterval = setInterval(myTimer, 100);
                function myTimer() {
                    e.target.style.backgroundColor = greenClr; //changed color
                    e.target.style.color = origionalClrBtn;
                    correctPromt.current.innerHTML = response.data.data.hint;
                    color++;
                    if (color & 1) {
                        document.body.style.backgroundColor = greenClr;
                    }
                    else {
                        document.body.style.backgroundColor = origionalClrBg;
                    }
                    //restore origional color
                    if (color > 5) {
                        clearInterval(colorInterval);
                        document.body.style.backgroundColor = origionalClrBg;
                        setTimeout(
                            () => removePromptText(e, true),
                            3000
                        );
                        // correctPromt.current.innerHTML = "";
                    }
                }
            }
            setTimeout(
                () =>
                    (triviaNumber.current > 0 && totalQuestions.current != 3)
                        ?
                        setAttempts(attempts + 1)
                        :
                        (triviaNumber.current > 0 && totalQuestions.current == 3)
                            ?
                            // console.log("totalQuestion = ",totalQuestions)
                            gotoNextLevel()

                            :
                            navigate('/rewards', { state: { id: videoId, questionUUID: questionUUID } }),
                3500
            );

        })
            .catch(error => {
                // console.log(error);
                // console.log(error.response.status,error.response.data.message);
                if (error.response.status === 404 && error.response.data.message === "Ko") {
                    return navigate('/reward', { state: { id: videoId } });
                }
            })

    }
    function gotoNextLevel() {
        clearInterval(interval.current);
        intervalState.current = false;
        navigate('/rewards', { state: { id: videoId, questionUUID: questionUUID, backToVideos: true, returnAttempt: attempts } })
    }

    function removePromptText(e, trueFalse) {
        let origionalClrBtn = "#3d3c3a";
        if (trueFalse == true) {
            e.target.style.backgroundColor = origionalClrBtn;
            e.target.style.color = "white";
        }
        if (trueFalse == false) {
            e.target.style.backgroundColor = origionalClrBtn;
            e.target.style.color = "white";
        }
        correctPromt.current.innerHTML = " ";
        setIsDisabled(false);
    }

    function correctBtnHighlight(hintText, showHide) {
        if (showHide == 1) {
            let greenClr = "#d5f04b";
            let origionalClrBtn = "#3d3c3a";
            if (questionChoice1.current.innerHTML == hintText) {
                questionChoice1.current.style.backgroundColor = greenClr; //changed color
                questionChoice1.current.style.color = origionalClrBtn;
            }
            if (questionChoice2.current.innerHTML == hintText) {
                questionChoice2.current.style.backgroundColor = greenClr; //changed color
                questionChoice2.current.style.color = origionalClrBtn;
            }
            if (questionChoice3.current.innerHTML == hintText) {
                questionChoice3.current.style.backgroundColor = greenClr; //changed color
                questionChoice3.current.style.color = origionalClrBtn;
            }
            if (questionChoice4.current.innerHTML == hintText) {
                questionChoice4.current.style.backgroundColor = greenClr; //changed color
                questionChoice4.current.style.color = origionalClrBtn;
            }
        }
        else {
            let origionalClrBtn = "#3d3c3a";
            if (questionChoice1.current.innerHTML == hintText) {
                questionChoice1.current.style.backgroundColor = origionalClrBtn; //changed color
                questionChoice1.current.style.color = "white";
            }
            if (questionChoice2.current.innerHTML == hintText) {
                questionChoice2.current.style.backgroundColor = origionalClrBtn; //changed color
                questionChoice2.current.style.color = "white";
            }
            if (questionChoice3.current.innerHTML == hintText) {
                questionChoice3.current.style.backgroundColor = origionalClrBtn; //changed color
                questionChoice3.current.style.color = "white";
            }
            if (questionChoice4.current.innerHTML == hintText) {
                questionChoice4.current.style.backgroundColor = origionalClrBtn; //changed color
                questionChoice4.current.style.color = "white";
            }

        }

    }

    function navigateHome() {
        return navigate("/home/landing");
    }
    function skipQuestion() {
        (triviaNumber.current > 0 && totalQuestions.current != 3)
            ?
            setAttempts(attempts + 1)
            :
            (triviaNumber.current > 0 && totalQuestions.current === 3)
                ?
                // console.log("totalQuestion = ", totalQuestions, totalQuestions.current)
                gotoNextLevel()

                :
                navigate('/rewards', { state: { id: videoId, questionUUID: questionUUID } })
    }
    return (
        <main className="container">
            <div className={`mb-4 ${styles.backToVideosBtnContainer}`}>
                <button type="button" className={styles.backToVidesoButton} onClick={navigateHome} disabled={isDisabled}>back to videos</button>
            </div>
            <div className={`mb-4 ${styles.skipQuestionBtnContainer}`}>
                <button type="button" className={styles.skipQuestionButton} onClick={skipQuestion} disabled={isDisabled}>skip</button>
            </div>
            <div className={styles.counter + ' d-flex justify-content-center flex-column'}>
                <div className={(showLinear > 5 ? `${styles.ml} ${styles.colorWhite} ${styles.timerWrapper} position-relative` : `${styles.visibilityHidden} ${styles.ml} ${styles.colorWhite} ${styles.timerWrapper} position-relative`)}>

                    <img className={(seconds >= 0 ? styles.starToken : `${styles.visibilityHidden} ${styles.starToken}`)} src={star_token} alt="" width="30" height="30" />
                    <div className={styles.moveableTimer}>
                        <div className={(seconds >= 0 ? `${styles.secondsCounter} ${styles.mt_equal}` : `${styles.secondsCounter} ${styles.mt_equal} ${styles.visibilityHidden}`)}> {seconds}</div>
                    </div>
                    <video className={(seconds < 0 ? styles.timerSmoke : `${styles.visibilityHidden} ${styles.timerSmoke}`)} src={timerSmoke} type="video/mp4" muted preload="metadata" controlsList='nodownload' id="playSmoke"></video>
                </div>
                <p className={(showLinear >= 6 ? `fs-14` : `${styles.visibilityHidden} fs-14`)}>win 100 coins with the correct answer</p>
            </div>
            <div className={styles.QuestionTimeContainer}>
                <div className={(showLinear > 0 ? styles.questionStatement : `${styles.questionStatement} ${styles.visibilityHidden}`)}>
                    <p className={styles.questionTitle} ref={questionTitle}></p>
                </div>
                <div className="d-flex flex-column mb-c4">

                    <>
                        <div className={(showLinear > 1 ? `${styles.mb_1}` : `${styles.mb_1} ${styles.visibilityHidden}`)}>
                            <button type="button" name="answer" disabled={isDisabled} onClick={(e) => { handleSubmit(e) }} className={`${styles.mb_0} ${styles.answerBtn}`} value={questionChoice1} ref={questionChoice1} ></button>
                        </div>


                        <div className={(showLinear > 2 ? styles.mb_1 : `${styles.mb_1} ${styles.visibilityHidden}`)}>
                            <button type="button" name="answer" disabled={isDisabled} onClick={(e) => { handleSubmit(e) }} className={`${styles.mb_0} ${styles.answerBtn}`} value={questionChoice2} ref={questionChoice2}></button>
                        </div>


                        <div className={(choices == 2 || showLinear < 4 ? styles.hidden : styles.mb_1)}>
                            <button type="button" name="answer" disabled={isDisabled} onClick={(e) => { handleSubmit(e) }} className={`${styles.mb_0} ${styles.answerBtn}`} value={questionChoice3} ref={questionChoice3}></button>
                        </div>


                        <div className={(choices == 2 || choices == 3 || showLinear < 5 ? styles.hidden : styles.mb_1)}>
                            <button type="button" name="answer" disabled={isDisabled} onClick={(e) => { handleSubmit(e) }} className={`${styles.mb_0} ${styles.answerBtn}`} value={questionChoice4} ref={questionChoice4}></button>
                        </div>
                    </>
                    <div className={`${styles.corectAnswerPrompt} ${styles.displayNone}`}>
                        <p id="corectPrompt" ref={correctPromt}></p>
                    </div>
                </div>
            </div>
        </main>

    );
}

export default React.memo(QuestionTime);