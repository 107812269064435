import React from 'react';
import styles from '../../styles/savedVideos/Categories.module.css';
import bulbOn from "../../images/v1/icons/trivia/did_you_know.svg";
import gaming from "../../images/v1/icons/trivia/gaming.svg";
// import seasons from "../../images/v1/icons/seasons.svg";
import seasons from "../../images/v1/icons/trivia/seasons.svg";
import languages from "../../images/v1/icons/trivia/language.svg";
import encyclopedia from "../../images/v1/icons/trivia/encyclopedia.svg";
import curriculum from "../../images/v1/icons/trivia/curriculum.svg";
import conscious from "../../images/v1/icons/trivia/conscious_living.svg";
import growth_mind_set from "../../images/v1/icons/trivia/growth_mind_set.svg";
function Categories({ getCategory }) {
    function handleClick(e) {
        let data = {
            category: e
        };
        getCategory(data);
    }
    return (
        <>
            <main className={styles.mainContainer}>
                <section className={styles.vidoesContainer}>
                    <div className={styles.basis_50}>
                        <div className={styles.pointer} onClick={() => handleClick("curriculam")}>
                            <img src={curriculum} alt="loading..." className={styles.categoryImage} />
                            <p className={styles.categoryText}>curriculum</p>
                        </div>

                    </div>
                    <div className={styles.basis_50}>
                        <div className={styles.pointer} onClick={() => handleClick("did you know")}>
                            <img src={bulbOn} alt="loading..." className={styles.categoryImage} />
                            <p className={styles.categoryText}>did you know</p>
                        </div>
                    </div>
                    <div className={styles.basis_50}>
                        <div className={styles.pointer} onClick={() => handleClick("language")}>
                            <img src={languages} alt="loading..." className={styles.categoryImage} />
                            <p className={styles.categoryText}>language</p>
                        </div>
                    </div>
                    <div className={styles.basis_50}>
                        <div className={styles.pointer} onClick={() => handleClick("encyclopedia")}>
                            <img src={encyclopedia} alt="loading..." className={styles.categoryImage} />
                            <p className={styles.categoryText}>encyclopedia</p>
                        </div>
                    </div>
                    <div className={styles.basis_50}>
                        <div className={styles.pointer} onClick={() => handleClick("conscious")}>
                            <img src={conscious} alt="loading..." className={styles.categoryImage} />
                            <p className={styles.categoryText}>conscious <br />living</p>
                        </div>
                    </div>
                    <div className={styles.basis_50}>
                        <div className={styles.pointer} onClick={() => handleClick("season")}>
                            <img src={seasons} alt="loading..." className={styles.categoryImage} />
                            <p className={styles.categoryText}>seasons</p>
                        </div>
                    </div>
                    <div className={styles.basis_50}>
                        <div className={styles.pointer} onClick={() => handleClick("gaming")}>
                            <img src={gaming} alt="loading..." className={styles.categoryImage} />
                            <p className={styles.categoryText}>gaming</p>
                        </div>
                    </div>
                    <div className={styles.basis_50}>
                        <div className={styles.pointer} onClick={() => handleClick("growth mind set")}>
                            <img src={growth_mind_set} alt="loading..." className={styles.categoryImage} />
                            <p className={styles.categoryText}>growth <br /> mind set</p>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}


export default React.memo(Categories)