import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../styles/home/search.module.css';
import LoaderBrown from "../Animations/LoaderBrown";
import { searchVideo, apiCallToFetchSuggestedTopics } from "../../ApiCalls/Home/api_v1_home";
function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}
function SearchPopup() {
    const navigate = useNavigate();
    const location = useLocation();
    const [locationState, setLocationState] = useState(location.state);
    const searchTextRef = useRef();
    const [isSearching, setIsSearching] = useState(false);
    const [isvideoFound, setIsVideoFound] = useState(false);
    // const [isSuggestionFound, setIsSuggestionFound] = useState(false);
    const infoText = useRef("Search any video")
    const [vidList, setVidLists] = useState([]);


    function handleSubmit(event) {
        event.preventDefault();
        // navigate(location.pathname);
        setIsSearching(true);
        setVidLists([]);
        // Access form values using refs
        const searchedItem = searchTextRef.current.value;
        // console.log(searchedItem);
        // Remove special characters using regex
        const sanitizedValue = searchedItem.replace(/[^a-zA-Z0-9 ]/g, '');
        // Perform form submission logic (API call) here
        let searchData = {
            "searchTerm": sanitizedValue,
        }
        searchVideo(searchData).then(function (res) {
            if (res.status == 200 && 'videos' in res.data.data) {
                setIsVideoFound(true);
                // console.log(res.data.data.videos);
                res.data.data.videos.forEach(element => {
                    // videoList.push({"vid":element.vid,"src":element.video,"type":"video/mp4"});
                    setVidLists(vidList => [...vidList, { "title": element.title, "thumbnail": element.thumbnail, 'language': element.language, 'uid': element.uid }]);
                })
            } else {
                infoText.current = res.data.data.additionalMsg;
                setIsVideoFound(false);
            }
            // Set loading to false to hide the loader
            setIsSearching(false);
        }).catch(function (error) {
            // console.log(error);
            setIsSearching(false);
        });
        // Clear the form fields
        searchTextRef.current.value = '';
    }
    function handleButtonClick(uuid) {
        // Set the location state
        // console.log(uuid);
        const locationState = { uid: uuid };

        // Navigate to the same route with the updated location state
        navigate(location.pathname, { state: locationState });
    };
    const [suggestedTopics, setSuggestedTopics] = useState([]);

    // Function to fetch suggested topics based on the searched keyword
    function fetchSuggestedTopics(keyword) {
        // Call the API function to fetch suggested topics
        // Replace 'apiCallToFetchSuggestedTopics' with the actual function that fetches suggested topics from your backend
        if (keyword == "") {
            setSuggestedTopics("");
            return;
        }
        let formdata = {
            "keyword": keyword
        }
        apiCallToFetchSuggestedTopics(formdata)
            .then((response) => {
                // Update the state with the fetched suggested topics
                let data = response.data.data.suggested_search;
                // console.log(response.data.data.suggested_search)
                // setIsSuggestionFound(true);
                setSuggestedTopics(data);
            })
            .catch((error) => {

                // console.log(error);
            });
    }

    // Custom debounce function
    const debouncedFetchTopics = useRef(debounce(fetchSuggestedTopics, 500)).current;

    // Handle changes to the search input
    function handleInputChange(event) {
        const searchedItem = event.target.value;
        const sanitizedValue = searchedItem.replace(/[^a-zA-Z0-9 ]/g, '');

        // Call the debounced function instead of the original function
        debouncedFetchTopics(sanitizedValue);
    }
    function handleSuggestedSearch(e, topic) {
        searchTextRef.current.value = topic;
        setSuggestedTopics("");
        handleSubmit(e);
    }
    return (
        <>
            <section className={styles.shareSection}>
                <form onSubmit={handleSubmit}>
                    <div className="input-group mb-4">
                        <input type="search" placeholder="e.g temperature" aria-describedby="button-addon5"
                            className={`form-control ${styles.searchInput}`} ref={searchTextRef} onChange={handleInputChange} />
                        <div className="input-group-append">
                            <button id="button-addon5" type="submit" className={`btn ${styles.searchBtn}`}>
                                <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Icons_MagnifyingGlass" overflow="hidden"><path d="M85.5 75.6 73 63.1C71.3 61.4 69 60.8 66.8 61.2L62.4 56.8C66.3 51.8 68.6 45.4 68.6 38.6 68.6 22.1 55.1 8.6 38.6 8.6 22.1 8.6 8.6 22.1 8.6 38.6 8.6 55.1 22.1 68.6 38.6 68.6 45.4 68.6 51.7 66.3 56.8 62.4L61.2 66.8C60.8 69 61.4 71.3 63.1 73L75.6 85.5C77 86.9 78.8 87.6 80.6 87.6 82.4 87.6 84.2 86.9 85.6 85.5 88.2 82.7 88.2 78.3 85.5 75.6ZM38.5 62.5C25.3 62.5 14.5 51.7 14.5 38.5 14.5 25.3 25.3 14.5 38.5 14.5 51.7 14.5 62.5 25.3 62.5 38.5 62.5 51.7 51.7 62.5 38.5 62.5Z" stroke="#FFFFFF" strokeWidth="3.12195" fill="none" /></svg>
                            </button>
                        </div>
                    </div>
                </form>
            </section>
            {isSearching && <div><LoaderBrown /></div>}
            {!isSearching && !isvideoFound && <div className={`position-relative ${styles.searchHint}`}> <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Icons_MagnifyingGlass" overflow="hidden"><path d="M85.5 75.6 73 63.1C71.3 61.4 69 60.8 66.8 61.2L62.4 56.8C66.3 51.8 68.6 45.4 68.6 38.6 68.6 22.1 55.1 8.6 38.6 8.6 22.1 8.6 8.6 22.1 8.6 38.6 8.6 55.1 22.1 68.6 38.6 68.6 45.4 68.6 51.7 66.3 56.8 62.4L61.2 66.8C60.8 69 61.4 71.3 63.1 73L75.6 85.5C77 86.9 78.8 87.6 80.6 87.6 82.4 87.6 84.2 86.9 85.6 85.5 88.2 82.7 88.2 78.3 85.5 75.6ZM38.5 62.5C25.3 62.5 14.5 51.7 14.5 38.5 14.5 25.3 25.3 14.5 38.5 14.5 51.7 14.5 62.5 25.3 62.5 38.5 62.5 51.7 51.7 62.5 38.5 62.5Z" stroke="#FFFFFF" strokeWidth="3.12195" fill="none" /></svg> <p className={styles.searchHintText}>{infoText.current}</p></div>}
            {/* Show suggested topics dropdown */}
            {suggestedTopics.length > 0 && (
                <div className={styles.suggestedTopicsContainer}>
                    <ul className={styles.suggestedTopicsUl}>
                        {suggestedTopics.map((topic, index) => (
                            <li key={index} onClick={(e) => { handleSuggestedSearch(e, topic) }}><svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Icons_MagnifyingGlass" overflow="hidden"><path d="M85.5 75.6 73 63.1C71.3 61.4 69 60.8 66.8 61.2L62.4 56.8C66.3 51.8 68.6 45.4 68.6 38.6 68.6 22.1 55.1 8.6 38.6 8.6 22.1 8.6 8.6 22.1 8.6 38.6 8.6 55.1 22.1 68.6 38.6 68.6 45.4 68.6 51.7 66.3 56.8 62.4L61.2 66.8C60.8 69 61.4 71.3 63.1 73L75.6 85.5C77 86.9 78.8 87.6 80.6 87.6 82.4 87.6 84.2 86.9 85.6 85.5 88.2 82.7 88.2 78.3 85.5 75.6ZM38.5 62.5C25.3 62.5 14.5 51.7 14.5 38.5 14.5 25.3 25.3 14.5 38.5 14.5 51.7 14.5 62.5 25.3 62.5 38.5 62.5 51.7 51.7 62.5 38.5 62.5Z" stroke="#FFFFFF" strokeWidth="3.12195" fill="none" /></svg> {topic}</li>
                        ))}
                    </ul>
                </div>
            )}
            {isvideoFound && <div className={styles.videoCardContainer}>
                {vidList.map((item, index) =>
                    <section className={styles.videoCard} key={`item_${index}`}>
                        <div className={styles.cardImageContainer}>
                            <img src={item.thumbnail} alt="loading ..." className={styles.w100} />
                        </div>
                        <div className={styles.cardDetailsContainer}>
                            <h1 className={styles.videoCardTitle}>{item.title}</h1>
                            <p className={styles.videoCardSubTitle}>{item.language}</p>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <button className={`btn ${styles.cardBtn}`} onClick={() => handleButtonClick(item.uid)}>Play</button>
                        </div>
                    </section>
                )}
            </div>}
        </>
    )
}

export default React.memo(SearchPopup);