import React, { useLayoutEffect, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import SfxMainAudio from "../AutoPlayAudio/SfxMainAudio";
import styles from '../../styles/savedVideos/SavedVideos.module.css';
import { getSavedVideos, getSavedVideosByCategory } from "../../ApiCalls/SavedVideos/api_v1_saved_videos";
import Categories from "./Categories";
import BufferingAnimation from '../Animations/BufferingAnimation';
function SavedVideos() {
    let navigate = useNavigate();
    const [videoList, setVideoLists] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [showCategoriesVideoSelected, setShowCategoriesVideoSelected] = useState(false);
    const [showCategoriesVideoLoaded, setShowCategoriesVideoLoaded] = useState(false);
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");
        document.title = "Saved | Ding";
    }, []);
    useEffect(() => {
        if (isLoaded === false) {
            getSavedVideos().then(response => {
                if (response.status === 200 && response.data.message === "Ko") {

                    // console.log("no videos found");
                    return;
                }
                if (response.status === 200 && response.data.message === "ok") {
                    setVideoLists([]);
                    response.data.data.forEach(element => {
                        // videoList.push({"vid":btoa(element.vid),"src":element.poster+"#t=0.1","type":"video/mp4"});
                        setVideoLists(videoList => [...videoList, { "vid": btoa(element.vid), "src": element.poster, "type": "video/mp4" }]);

                    })
                    // setVideoLists([...videoList,videoList]);
                    setIsLoaded(true);
                    return;

                }

            }).catch(error => {
                // console.log(error.response.status,error.response.data.message);
                // console.log(error);

            });
        }
    }, [isLoaded === false]);
    // useEffect(() => {
    //     getCategory();

    // //   return () => {
    // //     // second
    // //   }
    // }, [categoryLoaded.current])

    function getCategory(data) {
        setVideoLists([]);
        handleToggle(3);
        getSavedVideosByCategory(data).then(response => {
            if (response.status === 200 && response.data.message === "Ko") {
                return;
            }
            if (response.status === 200 && response.data.message === "ok") {
                response.data.data.forEach(element => {
                    setVideoLists(videoList => [...videoList, { "vid": btoa(element.vid), "src": element.poster + "#t=0.1", "type": "video/mp4" }]);

                })

                // setIsLoaded(true);
                setShowCategoriesVideoLoaded(true);
                return;

            }

        }).catch(error => {
            // console.log(error.response.status,error.response.data.message);
            // console.log(error);

        });
    }

    function handleToggle(e) {
        if (e === 1) {
            setShowCategories(prevState => false); //show saved videoes section
            setShowCategoriesVideoSelected(prevState => false); //show saved categories section
            setIsLoaded(false);
        }
        if (e === 2) {
            setShowCategories(prevState => true); //show saved categories section
            setShowCategoriesVideoSelected(prevState => false); //show saved categories section
        }
        if (e === 3) {
            setShowCategoriesVideoSelected(prevState => true); //show saved categories section
            setShowCategoriesVideoLoaded(false);
        }

    }
    function navigateHome() {
        return navigate("/home/landing");
    }

    return (
        <>
            <SfxMainAudio />
            <main className={styles.mainContainer}>
                <div className='d-flex flex-row'>

                    <h1 className={(!showCategories ? `${styles.activeLink} ${styles.leftSide}` : `${styles.leftSide}`)} onClick={(e) => handleToggle(1)}>
                        saved videos
                    </h1>


                    <h1 className={(showCategories ? `${styles.activeLink} ${styles.rightSide}` : `${styles.rightSide}`)} onClick={(e) => handleToggle(2)}>
                        categories
                    </h1>

                    {/* <h1 className={styles.rightSide}>categories</h1> */}
                </div>
                {
                    showCategories
                        ? <>
                            {showCategoriesVideoSelected ?
                                <section className={styles.vidoesContainer}>
                                    {showCategoriesVideoLoaded ?
                                        <>
                                            {videoList.map((item, index) =>
                                                <Link to={`/saved/watch?v=${item.vid}`} key={`linkKey_${index}`} id={index} link-key={index} v-refkey={item.vid} className={styles.videoLink}>
                                                    <img src={item.src} key={`posterKey_${index}`} alt="loading..." className={styles.videoImg} />
                                                </Link>
                                            )}

                                            {!videoList.length ? <p className="mt-2 mx-auto">NO VIDEOS FOUND</p> : null}
                                        </>
                                        : <BufferingAnimation />
                                    }
                                </section>
                                : <Categories getCategory={getCategory} />
                            }
                        </>
                        :
                        <section className={styles.vidoesContainer}>
                            {isLoaded ?
                                <>
                                    {videoList.map((item, index) =>
                                        <Link to={`/saved/watch?v=${item.vid}`} key={`linkKey_${index}`} id={index} link-key={index} v-refkey={item.vid} className={styles.videoLink}>
                                            <img src={item.src} key={`posterKey_${index}`} alt="loading..." className={styles.videoImg} />
                                        </Link>
                                    )}
                                    {!videoList.length ? <p className="mt-2 mx-auto">NO VIDEOS SAVED</p> : null}
                                </>
                                : <BufferingAnimation />}
                        </section>
                }
                <div className='mb-4'>
                    <button type="button" className={styles.backToVidesoButton} onClick={navigateHome}>back to videos</button>
                </div>
            </main>
        </>
    );
}


export default React.memo(SavedVideos)