import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import styles from '../../styles/redeem/redeemBody.module.css';
import pubg from '../../images/v1/login/pubg.png';
import fortnite from '../../images/v1/login/fortnite.png';
import roblox from '../../images/v1/login/roblox.png';
import valorant from '../../images/v1/login/valorant.png';
import callofduty from '../../images/v1/login/callofduty.png';
import { getItemPrice, getRedeemItem } from "../../ApiCalls/Redeem/api_v1_redeem";
import RedeemForm from "./RedeemForm";
import SfxMainAudio from "../AutoPlayAudio/SfxMainAudio";

function RedeemBody() {
    const welcomDing = useRef(null);
    const welcomeDv = localStorage.getItem("dv");
    const redeemItemsLoading = useRef(false);
    const [redeemPricingFromApi, setredeemPricingFromApi] = useState([]);
    let navigate = useNavigate();
    const [showWelcomeDing, setShowWelcomeDing] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [insufficentMsg, setInsufficentMsg] = useState(false);
    const [showForm, setShowForm] = useState({
        formConsentShow: false,
        formShow: false,
        resp: "",
        item: "",
        key: "",
        name: "",
        tel: "",
        uuid: "",

    });
    useEffect(() => {

        async function redeemItems() {
            try {
                const res = await getItemPrice();
                setredeemPricingFromApi(res.data.data);
                // Process the response as needed
            } catch (error) {
                // Handle errors
                setDisabled(true);
            } finally {
                // Update the loading status or perform any cleanup
                redeemItemsLoading.current = true;
            }
        }

        if (!redeemItemsLoading.current) {
            redeemItems();
        }
    }, [redeemItemsLoading.current]);
    useEffect(() => {

        const dv = localStorage.getItem("dv");
        if (!dv || dv === "1") {
            setShowWelcomeDing(true);
            welcomDing.current.classList.remove(styles.hidden);
            const timeout = setTimeout(() => {
                localStorage.setItem("dv", "2");
                navigate("/home/landing");
            }, 5000);

            return () => clearTimeout(timeout); // Cleanup function to clear the timeout
        }
    }, []);
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");
        document.title = "Redeem | Ding";
    }, []);
    // let displayNone = useRef();
    const handleClick = (e) => {
        let value = e.target.getAttribute('value');
        e.target.innerHTML = "please wait";
        e.target.style.backgroundColor = "#ce6a17";
        setDisabled(true);
        let itemData = {
            "item": value
        }
        getRedeemItem(itemData).then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            if (response.data.message === "Ko" && response.status === 200) {
                setInsufficentMsg(true);
                // displayNone.current.style.opacity = "1";
                setTimeout(() => {   // ***
                    setMessage();  // *** If you want to clear the error message as well
                }, 1500);
                // setTimeout(
                //     () => console.log(insufficentMsg),e.target.style.backgroundColor= "rgb(61, 60, 58)", e.target.innerHTML="redeem",setInsufficentMsg(false),setDisabled(false),
                //     50000
                //   );

            }
            else if (response.data.message === "ok" && response.status === 200) {
                let resp = response.data.data;
                let item = resp.item;
                let key = resp.key;
                let name = resp.name;
                let tel = resp.tel;
                let uuid = resp.uuid;

                setShowForm({ ...showForm, formShow: true, item: item, key: key, name: name, tel: tel, uuid: uuid });

                // console.log(response.data.data);
            }


        }).catch(error => {
            // console.log(error);
        });
        function setMessage() {
            // console.log(insufficentMsg); 
            e.target.style.backgroundColor = "rgb(61, 60, 58)"; e.target.innerHTML = "redeem"; setInsufficentMsg(false); setDisabled(false);
        }
    }
    function navigateHome() {
        return navigate("/home/landing");
    }
    return (
        <>
            <SfxMainAudio />
            {(!welcomeDv || welcomeDv === "1") && (
                <div className={styles.welcomeDingFixed}>
                    <div
                        ref={welcomDing}
                        className={`${styles.welcomeDingWrapper} ${styles.hidden
                            } text-white`}
                    >
                        <h2 style={{ fontSize: "20px", textAlign: "center" }}>
                            When you have <br />
                            enough coins, <br />
                            you can redeem to get <br />
                            your favorite reward
                        </h2>
                    </div>
                </div>
            )}
            {(showForm.formShow) ? <RedeemForm showForm={showForm} /> :
                <main className={styles.main} id="main">
                    <section className={`${styles.insufficentCoins} ` + (insufficentMsg ? `${styles.show}` : `${styles.hidden}`)}>
                        <p>Insufficent Coins</p>
                    </section>
                    <section className={styles.section}>
                        <div className={styles.leftSide}>
                            <img className={styles.img} src={pubg} alt="..." />
                        </div>
                        <div className={styles.rightSide}>
                            <div className={styles.rightSideText}>
                                {/* <p className={styles.text}>get 50 UCS <br /> for 5000 coins</p> */}
                                <p className={styles.text} dangerouslySetInnerHTML={{ __html: redeemPricingFromApi.pubg ? redeemPricingFromApi.pubg : "Loading..." }}></p>
                            </div>
                            <div className={styles.rightSideBtn}>
                                <button value="1" className={styles.btn} onClick={handleClick} disabled={isDisabled}>redeem</button>
                            </div>
                        </div>
                    </section>
                    <section className={styles.section}>
                        <div className={styles.leftSide}>
                            <img className={styles.img} src={fortnite} alt="" />
                        </div>
                        <div className={styles.rightSide}>
                            <div className={styles.rightSideText}>
                                {/* <p className={styles.text}>get 1000 v-bucks<br />for 30,000 coins</p> */}
                                <p className={styles.text} dangerouslySetInnerHTML={{ __html: redeemPricingFromApi.fortnite ? redeemPricingFromApi.fortnite : "Loading..." }}></p>
                            </div>
                            <div className={styles.rightSideBtn}>
                                <button value="2" className={styles.btn} onClick={handleClick} disabled={isDisabled}>redeem</button>
                            </div>
                        </div>
                    </section>
                    <section className={styles.section}>
                        <div className={styles.leftSide}>
                            <img className={styles.img} src={valorant} alt="" />
                        </div>
                        <div className={styles.rightSide}>
                            <div className={styles.rightSideText}>
                                {/* <p className={styles.text}>get 5 euro gift cards<br />for 40,000 coins</p> */}
                                <p className={styles.text} dangerouslySetInnerHTML={{ __html: redeemPricingFromApi.valorant ? redeemPricingFromApi.valorant : "Loading..." }}></p>
                            </div>
                            <div className={styles.rightSideBtn}>
                                <button value="4" className={styles.btn} onClick={handleClick} disabled={isDisabled}>redeem</button>
                            </div>
                        </div>
                    </section>
                    <section className={styles.section}>
                        <div className={styles.leftSide}>
                            <img className={styles.img} src={callofduty} alt="" />
                        </div>
                        <div className={styles.rightSide}>
                            <div className={styles.rightSideText}>
                                {/* <p className={styles.text}>get 1000 CPS<br /> for 30,000 coins</p> */}
                                <p className={styles.text} dangerouslySetInnerHTML={{ __html: redeemPricingFromApi.callofduty ? redeemPricingFromApi.callofduty : "Loading..." }}></p>
                            </div>
                            <div className={styles.rightSideBtn}>
                                <button value="5" className={styles.btn} onClick={handleClick} disabled={isDisabled}>redeem</button>
                            </div>
                        </div>
                    </section>
                    <div className='mb-4 mt-4'>
                        <button type="button" className={styles.backToVidesoButton} onClick={navigateHome}>back to videos</button>
                    </div>
                </main>

            }
        </>
    )
}
export default React.memo(RedeemBody);