import React, { useEffect, useState } from 'react';
import styles from '../../styles/footer/footer.module.css';
import { NavLink, useLocation } from 'react-router-dom';
// import home from '../../images/v1/home/home.svg';
// import bookmark from '../../images/v1/home/bookmark.svg';
// import wallet from '../../images/v1/home/wallet.svg';
// import profile from '../../images/v1/home/profile.png';
function Footer() {
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        (location.pathname === '/' || location.pathname === '/home' || location.pathname === '/home/landing') ? setIsActive(true) : setIsActive(false);

    }, [location.pathname])

    return (
        <>
            <div className={styles.footerContainer} onContextMenu={(e) => e.preventDefault()}>
                {/* <div className={styles.footerContainerCenter}> */}
                <div className={styles.footerBtn}>
                    <NavLink to='/home/landing' className={`${styles.footerLink} ${isActive ? "active" : ''}`}>
                        <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path d="M 23.951172 4 A 1.50015 1.50015 0 0 0 23.072266 4.3222656 L 8.859375 15.519531 C 7.0554772 16.941163 6 19.113506 6 21.410156 L 6 40.5 C 6 41.863594 7.1364058 43 8.5 43 L 18.5 43 C 19.863594 43 21 41.863594 21 40.5 L 21 30.5 C 21 30.204955 21.204955 30 21.5 30 L 26.5 30 C 26.795045 30 27 30.204955 27 30.5 L 27 40.5 C 27 41.863594 28.136406 43 29.5 43 L 39.5 43 C 40.863594 43 42 41.863594 42 40.5 L 42 21.410156 C 42 19.113506 40.944523 16.941163 39.140625 15.519531 L 24.927734 4.3222656 A 1.50015 1.50015 0 0 0 23.951172 4 z M 24 7.4101562 L 37.285156 17.876953 C 38.369258 18.731322 39 20.030807 39 21.410156 L 39 40 L 30 40 L 30 30.5 C 30 28.585045 28.414955 27 26.5 27 L 21.5 27 C 19.585045 27 18 28.585045 18 30.5 L 18 40 L 9 40 L 9 21.410156 C 9 20.030807 9.6307412 18.731322 10.714844 17.876953 L 24 7.4101562 z" /></svg>
                    </NavLink>
                </div>
                <div className={styles.footerBtn}>
                    <NavLink to="/saved" className={styles.footerLink}>
                        <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 12.8125 2 C 12.335938 2.089844 11.992188 2.511719 12 3 L 12 47 C 11.996094 47.359375 12.1875 47.691406 12.496094 47.871094 C 12.804688 48.054688 13.1875 48.054688 13.5 47.875 L 25 41.15625 L 36.5 47.875 C 36.8125 48.054688 37.195313 48.054688 37.503906 47.871094 C 37.8125 47.691406 38.003906 47.359375 38 47 L 38 3 C 38 2.449219 37.550781 2 37 2 L 13 2 C 12.96875 2 12.9375 2 12.90625 2 C 12.875 2 12.84375 2 12.8125 2 Z M 14 4 L 36 4 L 36 45.25 L 25.5 39.125 C 25.191406 38.945313 24.808594 38.945313 24.5 39.125 L 14 45.25 Z" /></svg>
                    </NavLink>
                </div>
                <div className={styles.footerBtn}>
                    <NavLink to="/redeem" className={styles.footerLink}>
                        <svg width="44" height="44" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" xmlSpace="preserve"><g id="Wallet"><path d="M57.7901001,7H6.2100005c-3.4238999,0-6.2099996,2.7901001-6.2099996,6.2196999v3.9922009v29.5683994v4.0078011C0.0000008,54.2128983,2.7871008,57,6.2119007,57h47.5762024c2.6661987,0,4.9391975-1.6922989,5.8182983-4.0564995C62.3984985,52.6615982,64,50.4676018,64,46.7803001V13.2196999C64,9.7901001,61.2138977,7,57.7901001,7z M53.7881012,55H6.2119007c-2.3222001,0-4.2118998-1.8895988-4.2118998-4.2118988v-4.0078011V17.2119007C2.0000007,14.8896999,3.8897007,13,6.2119007,13h47.5762024C56.1104012,13,58,14.8896999,58,17.2119007V24H45.9482994C41.0136986,24,37,28.1231003,37,33.1913986v1.6182022C37,39.8769989,41.0136986,44,45.9482994,44H58v6.7881012C58,53.1104012,56.1104012,55,53.7881012,55z M58,42H45.9482994C42.1171989,42,39,38.7743988,39,34.8096008v-1.6182022C39,29.2255993,42.1171989,26,45.9482994,26H58V42z M62,46.7803001c0,2.9328995-1.0900993,3.8256989-2.0046005,4.0984993C59.9958992,50.8483009,60,50.8186989,60,50.7881012V17.2119007C60,13.7870998,57.2128983,11,53.7881012,11H6.2119007c-1.5956998,0-3.048295,0.6101999-4.1497002,1.6029997C2.3630006,10.5697002,4.0994005,9,6.2100005,9h51.580101C60.1112976,9,62,10.8926001,62,13.2196999V46.7803001z" /><path d="M42,34c0,2.2061005,1.7939987,4,4,4c2.2061005,0,4-1.7938995,4-4c0-2.2059994-1.7938995-4-4-4C43.7939987,30,42,31.7940006,42,34z M46,32c1.1026039,0,2,0.8974991,2,2c0,1.1026001-0.8973961,2-2,2c-1.1025009,0-2-0.8973999-2-2C44,32.8974991,44.8974991,32,46,32z" /></g></svg>
                    </NavLink>
                </div>
                <div className={styles.footerBtn}>
                    <NavLink to="/profile" className={styles.footerLink}>
                        <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z" fill="currentColor" /> <path fillRule="evenodd" clipRule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z" fill="currentColor" /></svg>
                    </NavLink>
                </div>
                {/* </div> */}
            </div>
        </>
    );
}

export default React.memo(Footer);