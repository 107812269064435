import React from 'react';
import styles from '../../styles/home/share.module.css';
import whatsapp from "../../images/v1/icons/whatsapp.svg";
import sms from "../../images/v1/icons/sms.svg";
import clipboard from "../../images/v1/icons/clipboard.svg";
import facebook from "../../images/v1/icons/facebook.svg";
import twitter from "../../images/v1/icons/twitter-circled.svg";
// import {Link} from "react-router-dom";
function SharePopup({ vid, copyToClipboardHandler }) {
    // let shareUrl = "https://telenor-stg.ding.pk/share/watch?v=" + btoa(vid);
    let shareUrl = `${process.env.REACT_APP_URL}/share/watch?v=${btoa(vid)}`;

    function handleShare(e) {

        let isMobile = {
            Android: function () {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function () {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function () {
                return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
            },
            any: function () {
                return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
            }
        };
        if (isMobile.Android()) {
            if (e === 1) //clipboard
            {
                // console.log(shareUrl);
                // () =>  navigator.clipboard.writeText("https://seekho.mobi")
                if ("clipboard" in navigator) {
                    navigator.clipboard.writeText(shareUrl);
                } else {
                    document.execCommand("copy", true, shareUrl);
                }
                copyToClipboardHandler();
            }
            if (e === 2) //whatsapp
            {
                window.open('whatsapp://send?text=' + shareUrl);
            }
            if (e === 3) //sms
            {
                // setTimeout(function () { window.location = "https://play.google.com/store/apps/details?id=com.google.android.apps.messaging&hl=en&gl=US&pli=1"; }, 25);
                window.open('sms:?&body=' + shareUrl);
            }
            if (e === 4) //facebook
            {
                var facebookAppUrl = 'https://www.facebook.com/sharer.php?u=' + shareUrl;

                // window.location.href = (facebookAppUrl);
                window.open(facebookAppUrl, '_blank');

            }
            if (e === 5) //twitter
            {
                var twitterUrl = 'https://twitter.com/intent/tweet?url=' + shareUrl;

                // window.location.href = (facebookAppUrl);
                window.open(twitterUrl, '_blank');

            }
        }
        else {

        }

    }
    return (
        <>
            <section className={styles.shareSection}>
                <ul className={styles.ulShare}>
                    <li className={styles.liShare}>
                        <p onClick={() => handleShare(1)} className="text-center">
                            <img src={clipboard} alt="" className={styles.shareImage} />
                        </p>
                        <p className={styles.sharetext}>
                            Copy Link
                        </p>
                    </li>
                    <li className={styles.liShare}>
                        <p onClick={() => handleShare(3)} className="text-center">
                            <img src={sms} alt="" className={styles.shareImage} />
                        </p>
                        <p className={styles.sharetext}>
                            Sms
                        </p>
                    </li>
                    <li className={styles.liShare}>
                        <p onClick={() => handleShare(2)} className="text-center">
                            <img src={whatsapp} alt="" className={styles.shareImage} />
                        </p>
                        <p className={styles.sharetext}>
                            Whatsapp
                        </p>
                    </li>
                    <li className={styles.liShare}>
                        <p onClick={() => handleShare(2)} className="text-center">
                            <img src={whatsapp} alt="" className={styles.shareImage} />
                        </p>
                        <p className={styles.sharetext}>
                            whatsapp
                            <br />
                            Status
                        </p>
                    </li>
                    <li className={styles.liShare}>
                        <p onClick={() => handleShare(4)} className="text-center">
                            <img src={facebook} alt="" className={styles.shareImage} />
                        </p>
                        <p className={styles.sharetext}>
                            Facebook
                        </p>
                    </li>
                    <li className={styles.liShare}>
                        <p onClick={() => handleShare(5)} className="text-center">
                            <img src={twitter} alt="" className={styles.shareImage} />
                        </p>
                        <p className={styles.sharetext}>
                            Twitter
                        </p>
                    </li>
                </ul>
            </section>
        </>
    )
}

export default React.memo(SharePopup);